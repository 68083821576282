<template>
  <WsMain>
    <WsReadSection
      :id="_id"
      :modelName="modelName"
      :label="label"
      :fields="fields"
      :leftFields="leftFields"
      :rightFields="rightFields"
    ></WsReadSection>
  </WsMain>
</template>

<script>
import model from "@/__vue2stone_cms/models/shop_ship_area_setting";
export default {
  data: () => ({
    modelName: model.modelName,
    label: model.label,
    fields: model.fields,
    leftFields: ["area", "is_all_area_section", "area_sections", "ship_ways"],
    rightFields: [],
  }),

  computed: {
    _id() {
      return this.$route.params.id;
    },
  },
};
</script>

<style>
</style>